import Swiper from 'swiper';
import ScrollHint from 'scroll-hint'


if (window.matchMedia("(max-width: 768px)").matches) {
	new ScrollHint('.js-scrollable', {
		applyToParents: true,
		i18n: {
			scrollable: 'スクロールできます'
		}
	});
}

$(function () {

	var swiper = new Swiper(".productpage__single__photos .swiper-container", {
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
	});

	var appear = false;
	var pagetop = $('.pagetop');
	$(window).scroll(function () {
		if ($(this).scrollTop() > 100) {  //100pxスクロールしたら
			if (appear == false) {
				appear = true;
				pagetop.stop().animate({
					'bottom': '20px' //下から50pxの位置に
				}, 300); //0.3秒かけて現れる
			}
		} else {
			if (appear) {
				appear = false;
				pagetop.stop().animate({
					'bottom': '-120px' //下から-50pxの位置に
				}, 300); //0.3秒かけて隠れる
			}
		}
	});
	pagetop.click(function () {
		$('body, html').animate({ scrollTop: 0 }, 500); //0.5秒かけてトップへ戻る
		return false;
	});

	$(document).on('click', '.nav__open', function () {
		$(this).toggleClass('is-active');
		$(".nav__sp").toggleClass('is-active');
	})
	//
	$(document).on('click', '.nav .product', function () {
		$(this).toggleClass('is-active');
		$(".nav__product").toggleClass('is-active');
	})

	//
	if ($(".contactpage__form__countdown").length) {
		var nav_pos = $(".contactpage__form__countdown").offset().top;
		var nav_height = $(".contactpage__form__countdown").outerHeight();
		$(window).scroll(function () {
			if ($(this).scrollTop() > nav_pos - 120) {
				$(".contactpage__form__countdown").addClass("fixed");
			} else {
				$(".contactpage__form__countdown").removeClass("fixed");
			}
		});

		//
		var items = $(".contactpage__form").find('.rq').length + 1;
		$(".countdown").html(items);
		var i = 0;
		var d = 0;
		$(".contactpage__form").find('.rq,.address').change(function () {
			calc();
		});
		$(".contactpage__form").find('.rq,.address').keyup(function () {
			calc();
		});
		var calc = function () {
			i = 0;
			d = 0;
			$(".contactpage__form").find('.rq').each(function () {
				if ($(this).val() !== "") {
					i++;
				}
			});
			var noerror = false;
			$(".contactpage__form").find('.address').each(function () {
				if ($(this).val() !== "") {
					noerror = true;
				} else {
					noerror = false;
					return;
				}
			});
			if (noerror) {
				d = 1;
			}
			$(".countdown").html(items - i - d);
		};

	}


})
