import Swiper from 'swiper';
import vegas from 'vegas';
const moment = require('moment');
/**
	* 単純なサンプル
	* moment.jsを使って現在日付をconsole出力するだけ
	*/
$(function () {
	console.log(moment().format("YYYY-MM-DD"));
	if ($(".toppage__mainimage").length) {
		var swiper = new Swiper(".toppage__product__list", {
			slidesPerView: 2,
			slidesPerGroup: 2,
			slidesPerColumn: 2,
			spaceBetween: 0,
			pagination: {
				el: ".swiper-pagination",
				clickable: true,
				renderBullet: function (index, className) {
					return '<span class="' + className + '">' + (index + 1) + "</span>";
				},
			},
			navigation: {
				nextEl: ".swiper-button-next",
				prevEl: ".swiper-button-prev",
			},
			breakpoints: {
				769: {
					slidesPerView: 4,
					slidesPerGroup: 4,
					slidesPerColumn: 1,
				}
			}
		});
		$(".toppage__mainimage").addClass('load');
		$(".header").addClass('none');

		$.ajax({
			type: "get",
			url: "http://bigplain.co.jp/wp-json/wp/v2/mainslide?_fields=image&order=desc",
			dataType: "json",
			success: function (data) {
				var sildearr = [];
				for (var i in data) {
					sildearr.push({ 'src': data[i]['image'] });
				}
				setTimeout(() => {
					$(".toppage__mainimage .text").each(function (index) {
						setTimeout(() => {
							$(this).addClass('is-anim');
						}, 500 * index);
					})
					setTimeout(() => {
						$(".header").addClass('is-fade');
						$(".toppage__mainimage").addClass('is-open');
						$(".toppage__mainimage__slide").vegas({
							slides: sildearr,
							delay: 6000,
							//transitionDuration: 1000,
							firstTransitionDuration: 1000,
							timer: false,
							animation: 'kenburnsLeft'
						});
					}, 3000);
				}, 1500);
			}
		});
	}
});
